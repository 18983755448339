import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useLayoutEffect, useState } from 'react'

import text from './text.json'

type Contents = typeof text['en']
const validLanguageCodes = Object.keys(text)

export default memo(function Error404() {
  const [contents, setContents] = useState<Contents>(text.en)

  useLayoutEffect(() => {
    const language =
      (window.navigator as any).userLanguage || window.navigator.language
    const languageCode = language.substring(0, 2).toLowerCase()

    if (validLanguageCodes.includes(languageCode)) {
      setContents(text[languageCode as 'en'])
    }
  }, [])

  return (
    <Container>
      <Wrapper>
        <Logo
          src="/logo.svg"
          width="214"
          height="40"
          alt="Mira Hotels & Resort"
        />
        <Title>{contents.title}</Title>
        <Text>{contents.text}</Text>
        <CTA
          label={useVocabularyData('back-to-home', contents.code)}
          URL={`/${contents.prefix}`}
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  position: fixed;
  top: 0;
  left: 0;
`

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    width: 90%;
  }
`

const Logo = styled.img`
  margin-bottom: 3.75rem;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  letter-spacing: 0.125rem;
  line-height: 2.75rem;
  text-transform: uppercase;
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 0.75rem;
`

const CTA = styled(Button)`
  margin-top: 3.75rem;
`
